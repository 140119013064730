import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { IoLogoGithub } from "react-icons/io";
import BackButtonHeader from "../../components/BackButtonHeader";
import "./projects.scss";

function PAW(): JSX.Element {
  return (
    <div className="project-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eshed Margalit | Paper a Week</title>
        <html lang="en" />
      </Helmet>
      <BackButtonHeader title="Paper a Week" />

      <div className="project-info">
        <p>
          Paper a Week began as an experiment in personal accountability when I
          was a 3rd year PhD student. The idea is simple: read and take
          structured notes on one academic paper per week. Meeting that goal has
          helped me build a literature reading habit and organize my notes in a
          searchable, sortable, and shareable format. Wanting to share the
          benefits of this system with others, I built out Paper a Week into a
          standalone service capable of finding papers online, maintaining a
          reading list, writing rich notes with Markdown and LaTeX support, and
          providing habit-tracker statistics. Making an account is fast and
          free. Try it out!
        </p>

        <StaticImage
          className="project-image"
          src="../../images/projectImages/paw.png"
          alt="A paper airplane, the Paper a Week logo"
          placeholder="tracedSVG"
          height={300}
          width={300}
        />
      </div>

      <hr />
      <a href="https://paperaweek.com" target="_blank" rel="noreferrer">
        <h3>Visit the Paper a Week Website</h3>

        <div className="center">
          <StaticImage
            src="../../images/projectImages/paw_demo.png"
            alt="Laptop displaying the Paper a Week website"
            placeholder="tracedSVG"
            height={430}
            width={750}
          />
        </div>
      </a>

      <a
        href="https://paperaweek.com/profiles/113214769483919341732"
        target="_blank"
        rel="noreferrer"
      >
        <h3>Browse My Public Paper a Week Profile</h3>
      </a>

      <hr />
      <h3>Explore the Code</h3>
      <p>
        A big part of my motivation for making{" "}
        <a href="https://paperaweek.com" target="_blank" rel="noreferrer">
          Paper a Week
        </a>{" "}
        was to learn modern web technologies including React, Redux, Express,
        Node.js, MongoDB, Docker, Google Cloud Run, Typescript, and more. That
        effort would have been much more painful if not for the expert guidance
        and patience of my mentor and identical twin brother,{" "}
        <a href="https://aradmargalit.com" target="_blank" rel="noreferrer">
          Arad
        </a>
        . Given the nature of the project, I've made all of the code and commit
        history available to anybody interested.
      </p>

      <a
        href="https://www.github.com/eshedmargalit/paperaweek"
        target="_blank"
        rel="noreferrer"
      >
        <div className="project-link">
          <div className="title">
            <IoLogoGithub /> Paper a Week Github Repository
          </div>
        </div>
      </a>

      <hr />
      <h3 id="paw-reflections">Reflections</h3>
      <p>
        As of this writing, I had done 200 consecutive weekly reviews. My 200th
        review aligned with finishing my PhD, and I took the opportunity to take
        a break from weekly reviews and focus on other things. Here are my
        thoughts on Paper a Week after nearly 4 years of regular use:
      </p>
      <ul>
        <li>
          <strong>On Frequency: </strong>A lot of people might see 1 paper /
          week as unambitious, but I’ve found that it’s a great balance of
          regularity and sustainability. There were times in my PhD (like before
          my qualifying exam) where I was carefully reading many papers in a
          week, but there were also times — whole quarters sometimes! — where
          other things felt more urgent and I barely skimmed any papers, let
          alone read them carefully. 1 paper per week is a tangible target, and
          if I ever wanted to read more than that, I did.
        </li>

        <li>
          <strong>On Skimming: </strong>Taking notes on 1 paper / week doesn’t
          necessarily mean looking at 1 paper / week. For every paper I took
          notes on, there are 3-5 that I skim or stop reading halfway through.
          In fact, by choosing “The One” paper I’ll read that week, I usually
          end up skimming many alternatives and reckoning with whether a paper
          is actually relevant to my interests.
        </li>

        <li>
          <strong>On Old and New Literature: </strong>Reading a paper-a-week
          helped me do two things that I find important: First, it pushed me to
          actually scan the new literature on a weekly basis, instead of relying
          on Twitter to float a relevant article once a month. Second, in weeks
          where there was nothing relevant and new to read, it pushed me to find
          older papers that had flown under my radar.
        </li>

        <li>
          <strong>On a Personal Scientific Brand: </strong>Having a list of many
          papers that were of interest to me has helped defined my messy corner
          of scientific interests. Reflecting on all of the notes I’ve taken,
          the intersection of my interests in machine learning, vision, and
          cortical development is more apparent. After many weeks, Paper a Week
          provides an honest reflection of the work you find interesting,
          accessible, and important.
        </li>

        <li>
          <strong>On Memory: </strong>My memory for papers I’ve read isn’t
          great, and I’ve already used paperaweek.com to go back and remind
          myself of what my impressions were at the time I read a paper.
        </li>

        <li>
          <strong>On Notes as a Form of Reviewing: </strong>Taking critical
          notes on one paper per week has helped make me a better reviewer and
          critic of scientific work in journals, talks, and conferences. It's
          not a replacement for peer review, but it's still made me better at
          noticing details in papers.
        </li>
      </ul>
    </div>
  );
}

export default PAW;
